// Attachments
import ladder_5ft from '../assets/images/attachments/5ft/ladder.png';
import rock_wall_5ft from '../assets/images/attachments/5ft/rock-wall.png';
import rope_ladder_5ft from '../assets/images/attachments/5ft/rope-ladder.png';
import tube_slide_5ft from '../assets/images/attachments/5ft/tube-slide.png';
import vinyl_ramp_5ft from '../assets/images/attachments/5ft/vinyl-ramp.png';
import wave_slide_5ft from '../assets/images/attachments/5ft/wave-slide.png';
import tree_climber from '../assets/images/attachments/5ft/tree-climber.png';

import ladder_7ft from '../assets/images/attachments/7ft/ladder.png';
import rock_wall_7ft from '../assets/images/attachments/7ft/rock-wall.png';
import rope_ladder_7ft from '../assets/images/attachments/7ft/rope-ladder.png';
import scoop_slide_7ft from '../assets/images/attachments/7ft/scoop-slide.png';
import tube_slide_7ft from '../assets/images/attachments/7ft/tube-slide.png';

import monkey_bar_a_frame from '../assets/images/attachments/universal/monkey-bar-a-frame.png';
import swing_beam_three_position from '../assets/images/attachments/universal/swing-beam-three-position.png';
import swing_beam_two_position from '../assets/images/attachments/universal/swing-beam-two-position.png';

// Connectors
import monkey_bar_bridge from '../assets/images/connectors/universal/monkey-bar-bridge.png';
import monkey_bar from '../assets/images/connectors/universal/monkey-bar.png';
import swing_bridge_three_position from '../assets/images/connectors/universal/swing-bridge-three-position.png';
import swing_beam from '../assets/images/connectors/universal/three-position-swing-beam.png';

// Towers
// import tower from '../assets/images/towers/universal/tower.png';
import tower_five_foot from '../assets/images/towers/universal/five-foot-tower.png';
import tower_seven_foot from '../assets/images/towers/universal/seven-foot-tower.png';
import tower_one_half from '../assets/images/towers/universal/tower-one-and-a-half.png';
import tower_double from '../assets/images/towers/universal/tower-double.png';

export const elements = {
  attachment: {
    '5ft': {
      ladder: {
        name: 'Ladder',
        image: ladder_5ft,
        size: { height: 0.75, width: 0.7 },
        dimensions: { length: 3, width: 3 },
        price: 304.0,
      },
      rock_wall: {
        name: 'Rock Wall',
        image: rock_wall_5ft,
        size: { height: 1.1, width: 1.05 },
        dimensions: { length: 3, width: 3 },
        price: 613.0,
      },
      rope_ladder: {
        name: 'Rope Ladder',
        image: rope_ladder_5ft,
        size: { height: 0.55, width: 1.05 },
        dimensions: { length: 3, width: 3 },
        price: 458.0,
      },
      tube_slide: {
        name: 'Tube Slide',
        image: tube_slide_5ft,
        size: { height: 1.0, width: 1.0 },
        dimensions: { length: 5, width: 5 },
        price: 711.0,
      },
      vinyl_ramp: {
        name: 'Vinyl Ramp',
        image: vinyl_ramp_5ft,
        size: { height: 1.75, width: 1 },
        dimensions: { length: 8, width: 8 },
        price: 715.0,
      },
      wave_slide: {
        name: 'Wave Slide',
        image: wave_slide_5ft,
        size: { height: 1.95, width: 0.5 },
        dimensions: { length: 3, width: 8 },
        price: 298.0,
      },
      tree_climber: {
        name: 'Tree Climber',
        image: tree_climber,
        size: { height: 1.0, width: 0.95 },
        dimensions: { length: 3, width: 3 },
        price: 438.0,
      },
    },
    '7ft': {
      ladder: {
        name: 'Ladder',
        image: ladder_7ft,
        size: { height: 1.0, width: 0.65 },
        dimensions: { length: 3, width: 3 },
        price: 376.0,
      },
      rock_wall: {
        name: 'Rock Wall',
        image: rock_wall_7ft,
        size: { height: 1.0, width: 1.0 },
        dimensions: { length: 3, width: 3 },
        price: 623.0,
      },
      rope_ladder: {
        name: 'Rope Ladder',
        image: rope_ladder_7ft,
        size: { height: 1.0, width: 1.0 },
        dimensions: { length: 3, width: 3 },
        price: 561.0,
      },
      scoop_slide: {
        name: 'Scoop Slide',
        image: scoop_slide_7ft,
        size: { height: 3.25, width: 1.0 },
        dimensions: { length: 3, width: 12 },
        price: 716.0,
      },
      tube_slide: {
        name: 'Tube Slide',
        image: tube_slide_7ft,
        size: { height: 1.0, width: 1.0 },
        dimensions: { length: 5, width: 5 },
        price: 948.0,
      },
    },
    universal: {
      default: {
        none: {
          name: 'None',
          image: null,
          size: null,
          dimensions: null,
          price: 0.0,
        },
        monkey_bar_a_frame: {
          name: 'Monkey Bars',
          image: monkey_bar_a_frame,
          size: { height: 3.55, width: 3.55 },
          dimensions: { length: 5, width: 10 },
          price: 1955.0, // *Check Price
        },
      },
      swings: {
        swing_beam_three_position: {
          name: '3 Position Swing Beam',
          image: swing_beam_three_position,
          size: { height: 1.95, width: 1.95 },
          dimensions: { length: 5, width: 10 },
          price: 1571.0,
        },
        swing_beam_two_position: {
          name: '2 Position Swing Beam',
          image: swing_beam_two_position,
          size: { height: 1.7, width: 1.7 },
          dimensions: { length: 5, width: 8 },
          price: 1468.0,
        },
      },
    },
  },
  connector: {
    '5ft': {},
    '7ft': {},
    universal: {
      monkey_bars: {
        monkey_bar_bridge: {
          name: 'Bridge w/Monkey Bars',
          image: monkey_bar_bridge,
          size: { height: 2.0, width: 1.0 },
          dimensions: { length: 5, width: 10 },
          price: 2950.0, // *Check Price
        },
        monkey_bar: {
          name: 'Monkey Bars',
          image: monkey_bar,
          size: { height: 2.0, width: 1.0 },
          dimensions: { length: 5, width: 10 },
          price: 2014.0,
        },
      },
      swings: {
        swing_bridge_three_position: {
          name: 'Bridge w/3 Swings',
          image: swing_bridge_three_position,
          size: { height: 2.0, width: 1.0 },
          dimensions: { length: 5, width: 10 },
          price: 3039.0,
        },
        swing_beam: {
          name: 'Swing Beam',
          image: swing_beam,
          size: { height: 2.0, width: 1.0 },
          dimensions: { length: 5, width: 10 },
          price: 1571.0,
        }, // *Check Price
      },
    },
  },
  tower: {
    '5ft': {
      tower_five_foot: {
        name: '5ft Tower',
        image: tower_five_foot,
        size: { height: 1.0, width: 1.0 },
        dimensions: { length: 5, width: 5 },
        price: 1833.0,
      },
    },
    '7ft': {
      tower_seven_foot: {
        name: '7ft Tower',
        image: tower_seven_foot,
        size: { height: 1.0, width: 1.0 },
        dimensions: { length: 5, width: 5 },
        price: 1880.0,
      },
      tower_one_half: {
        name: '7ft + 1/2 Tower',
        image: tower_one_half,
        size: { height: 2.0, width: 1.0 },
        dimensions: { length: 5, width: 5 },
        price: 3713.0,
      },
      tower_double: {
        name: '7ft + 7ft Tower',
        image: tower_double,
        size: { height: 1.0, width: 2.0 },
        dimensions: { length: 5, width: 5 },
        price: 3760.0,
      },
    },
    universal: {},
  },
  mist_system: {
    one_tower: {
      yes: {
        name: 'Yes',
        image: null,
        size: null,
        dimensions: null,
        price: 309.0,
      },
      no: {
        name: 'No',
        image: null,
        size: null,
        dimensions: null,
        price: 0.0,
      },
    },
    one_half_tower: {
      yes: {
        name: 'Yes',
        image: null,
        size: null,
        dimensions: null,
        price: 464.0,
      },
      no: {
        name: 'No',
        image: null,
        size: null,
        dimensions: null,
        price: 0.0,
      },
    },
    two_tower: {
      yes: {
        name: 'Yes',
        image: null,
        size: null,
        dimensions: null,
        price: 515.0,
      },
      no: {
        name: 'No',
        image: null,
        size: null,
        dimensions: null,
        price: 0.0,
      },
    },
    two_half_tower: {
      yes: {
        name: 'Yes',
        image: null,
        size: null,
        dimensions: null,
        price: 618.0,
      },
      no: {
        name: 'No',
        image: null,
        size: null,
        dimensions: null,
        price: 0.0,
      },
    },
  },
  tower_base: {
    '5ft': {},
    '7ft': {},
    universal: {
      none: {
        name: 'None',
        image: null,
        size: null,
        dimensions: null,
        price: 0.0,
      },
      picnic_table: {
        name: 'Picnic Table',
        image: null,
        size: null,
        dimensions: null,
        price: 283.0,
      },
      playhouse: {
        name: 'Playhouse',
        image: null,
        size: null,
        dimensions: null,
        price: 977.0,
      },
      cafe: {
        name: 'Cafe',
        image: null,
        size: null,
        dimensions: null,
        price: 359.0,
      },
      playhouse_and_cafe: {
        name: 'Playhouse + Cafe',
        image: null,
        size: null,
        dimensions: null,
        price: 1336.0,
      },
    },
  },
  roofs: {
    '1 Tower': {
      none: {
        name: 'None',
        image: null,
        size: null,
        dimensions: null,
        price: 0.0,
      },
      roof_vinyl: {
        name: 'Vinyl Roof',
        image: null,
        size: null,
        dimensions: null,
        price: 510.0,
      },
      roof_tarp: {
        name: 'Tarp Roof',
        image: null,
        size: null,
        dimensions: null,
        price: 386.0,
      },
    },
    '2 Towers': {
      none: {
        name: 'None',
        image: null,
        size: null,
        dimensions: null,
        price: 0.0,
      },
      roof_vinyl: {
        name: 'Vinyl Roof',
        image: null,
        size: null,
        dimensions: null,
        price: 1020.0, // 510.0 * 2
      },
      roof_tarp: {
        name: 'Tarp Roof',
        image: null,
        size: null,
        dimensions: null,
        price: 772.0, // 386.0 * 2
      },
    },
    '3 Towers': {
      none: {
        name: 'None',
        image: null,
        size: null,
        dimensions: null,
        price: 0.0,
      },
      roof_vinyl: {
        name: 'Vinyl Roof',
        image: null,
        size: null,
        dimensions: null,
        price: 1530.0, // 510.0 * 3
      },
      roof_tarp: {
        name: 'Tarp Roof',
        image: null,
        size: null,
        dimensions: null,
        price: 1158.0, // 386.0 * 3
      },
    },
  },
  add_ons: {
    '5ft': {},
    '7ft': {},
    universal: {
      none: {
        name: 'None',
        image: null,
        size: null,
        dimensions: null,
        price: 0.0,
      },
      steering_wheel: {
        name: 'Steering Wheel',
        image: null,
        size: null,
        dimensions: null,
        price: 26.0,
      },
      telescope: {
        name: 'Telescope',
        image: null,
        size: null,
        dimensions: null,
        price: 26.0,
      },
      steering_wheel_and_telescope: {
        name: 'Steering Wheel + Telescope',
        image: null,
        size: null,
        dimensions: null,
        price: 52.0,
      },
    },
  },
  accent_colors: {
    '5ft': {},
    '7ft': {},
    universal: {
      blue: {
        name: 'Blue',
        image: null,
        size: null,
        dimensions: null,
        price: 0.0,
      },
      green: {
        name: 'Green',
        image: null,
        size: null,
        dimensions: null,
        price: 0.0,
      },
      gray: {
        name: 'Gray',
        image: null,
        size: null,
        dimensions: null,
        price: 0.0,
      },
    },
  },
  swings: {
    '5ft': {},
    '7ft': {},
    universal: {
      none: {
        name: 'None',
        image: null,
        size: null,
        dimensions: null,
        price: 0.0,
      },
      belt: {
        name: 'Belt Swing',
        image: null,
        size: null,
        dimensions: null,
        price: 46.0,
      },
      trapeze_bar: {
        name: 'Trapeze Bar',
        image: null,
        size: null,
        dimensions: null,
        price: 46.0,
      },
      glider: {
        name: 'Glider Swing',
        image: null,
        size: null,
        dimensions: null,
        price: 88.0,
      },
      bucket: {
        name: 'Bucket Swing',
        image: null,
        size: null,
        dimensions: null,
        price: 46.0,
      },
      net: {
        name: 'Net Swing',
        image: null,
        size: null,
        dimensions: null,
        price: 77.0,
      },
    },
  },
};
